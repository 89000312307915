import React from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import './App.css';
import AppHeader from "./components/app/AppHeader";
import Login from "./routes/Login";
import Callback from "./routes/callback";
import PrivateRoutes from "./routes/PrivateRoutes";
import {IconSettings} from "@salesforce/design-system-react";
import actionSprite from '@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg';
import customSprite from '@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg';
import utilitySprite from '@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg';
import standardSprite from '@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg';
import doctypeSprite from '@salesforce-ux/design-system/assets/icons/doctype-sprite/svg/symbols.svg';
import '@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.min.css';
import {UserProvider} from "./context/users";
import NotFound from "./components/app/NotFound";

function App() {
  return (
      <IconSettings
          standardSprite={standardSprite}
          utilitySprite={utilitySprite}
          actionSprite={actionSprite}
          doctypeSprite={doctypeSprite}
          customSprite={customSprite}
      >
      <UserProvider>
          <><BrowserRouter>
              <Routes>
                  <Route element={<PrivateRoutes />}>
                      <Route element={<AppHeader/>} path="/"/>
                      <Route element={<AppHeader requestType='view'/>} path="/access-requests/:accessId"/>
                      <Route element={<AppHeader requestType='approve'/>} path="/slack/approve/:accessId"/>
                      <Route element={<AppHeader requestType='reject'/>} path="/slack/reject/:accessId"/>
                      <Route element={<AppHeader requestType='slack_channel_update'/>} path="/team"/>
                      <Route element={<AppHeader requestType='service_team_update'/>} path="/update-accounts-team"/>
                      <Route element={<AppHeader requestType='account_closure'/>} path="/account-closure"/>
                      <Route path="*" element={<NotFound />} />
                  </Route>
                  <Route element={<Login/>} path="/login"/>
                  <Route element={<Callback />} path="/auth/salesforce/callback"/>
              </Routes>
          </BrowserRouter></>
      </UserProvider>
      </IconSettings>
  );
}

export default App;
