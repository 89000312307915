import React, {useEffect, useMemo, useState} from 'react';
import {
    getCodeVerifier,
    parseAuthDataFromFragment,
    validateAuthData
} from "./helper";
import {Link, useNavigate} from "react-router-dom";
import jwt_decode from 'jwt-decode'; // Make sure to install @types/jwt-decode if it's available.
import config from "../config/environments";
import {Toast, ToastContainer} from "@salesforce/design-system-react";

type DecodedToken = {
    email?: string;
    [key: string]: any;
};

type RequestData = {
    client_id: string;
    code_verifier: string;
    grant_type: string;
    redirect_uri: string;
    code: string;
};

const Callback: React.FC = () => {
    const navigate = useNavigate();

    const oauthConfig = (config.backup_domain.enabled && window.location.hostname.startsWith("dashboard-bak")) ? config.backup_domain.oauth : config.oauth;

    const redirectUrl = useMemo(() => {
        const url = new URL(window.location.origin);
        url.pathname = oauthConfig.redirect_path as string;
        return url;
    }, []);

    const auth_data = parseAuthDataFromFragment();
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {

        try {
            validateAuthData(auth_data);
        } catch (e: any) {
            setError(e.message);
        }

        const requestData: RequestData = {
            client_id: oauthConfig.client_id as string,
            code_verifier: getCodeVerifier() as string,
            grant_type: "authorization_code",
            redirect_uri: redirectUrl.toString(),
            code: auth_data.code as string
        };

        const keys: (keyof RequestData)[] = ['client_id', 'code_verifier', 'grant_type', 'redirect_uri', 'code'];

        let formBody: string[] = [];
        for (const key of keys) {
            const value = requestData[key];
            if (value !== null && value !== undefined) {
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(value);
                formBody.push(encodedKey + '=' + encodedValue);
            }
        }
        const formData = formBody.join('&');

        fetch( oauthConfig.token_url as string, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData,
        })
            .then(qkResponse => qkResponse.json())
            .then(data => {
                const decoded: DecodedToken = jwt_decode(data.access_token);
                if (!decoded.email) {
                    throw Error(`JWT token contains no email. ${JSON.stringify(decoded)}`)
                }
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('authenticated', 'true');
                localStorage.removeItem('pcsk-login-oauth-pkce-code-verifier')
                localStorage.removeItem('pcsk-login-oauth-state')

                const attemptedURL = localStorage.getItem('attemptedURL');
                if (attemptedURL) {
                    localStorage.removeItem('attemptedURL');
                    navigate(attemptedURL);
                } else {
                    navigate('/');
                }

            })
            .catch(error => {
                console.error(error);
            });
    }, [auth_data, navigate, redirectUrl]);

    return (
        <>
            <ToastContainer>
                {error && (
                    <Toast
                        labels={{
                            heading: 'Error',
                            details: (
                                <>
                                    {error}{' '}
                                    <Link to="/"> Go to Home Page</Link>
                                </>
                            )
                        }}
                        variant="error"
                        onRequestClose={() => {
                            navigate('/');
                        }}
                    />
                )}
            </ToastContainer>
        </>
    );

}

export default Callback;
