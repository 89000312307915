import React from "react";
import { Icon, PageHeader, Tabs, TabsPanel } from "@salesforce/design-system-react";
import MyAccounts from "./my_accounts/MyAccounts";
import { selectedAccountInfo } from "../../utils/types/account";
import {ACCOUNT_MANAGEMENT_TABS_METADATA, CONSTANT_ACCOUNT} from "../../constants/accountManagement";
import { useUser } from "../../context/users";
import AccountVending from "./account_vending/AccountVending";
import { ToastState } from "../../utils/types/toast";
import UpdateAccountDetails from "./update_account_details/UpdateAccountDetails";
import AccountClosure from "./account_closure/AccountClosure";

type AccountManagementProps = {
    activeTab: string;
    setActiveTab: (tab: string) => void;
    activeSubTab: number;
    setActiveSubTab: (tabID: number) => void;
    selectedAccount: selectedAccountInfo;
    setSelectedAccount: (accountInfo: selectedAccountInfo) => void;
    toast: ToastState;
    setToast: React.Dispatch<React.SetStateAction<ToastState>>;
    requestType?: string
};

const AccountManagementObjectPage: React.FC<AccountManagementProps> = (props) => {
    const titleLabel = ACCOUNT_MANAGEMENT_TABS_METADATA[props.activeSubTab];

    const handleTabSelect = (selectedIndex: number, data: any) => {
        props.setActiveSubTab(selectedIndex);
        props.setActiveSubTab(selectedIndex);
        props.setSelectedAccount({
            account_id: "",
        });
    };

    const { user } = useUser();

    return (
        <div className="slds-m-around--x-small">
            <div className="slds-m-bottom--x-small">
                <PageHeader
                    icon={
                        <Icon
                            assistiveText={{ label: "User" }}
                            category="standard"
                            name="account"
                        />
                    }
                    label={CONSTANT_ACCOUNT.ACCOUNT_MANAGEMENT}
                    title={titleLabel}
                    variant="object-home"
                />
            </div>
            <Tabs
                variant="vertical"
                id="access_requests"
                selectedIndex={props.activeSubTab}
                onSelect={handleTabSelect}
            >
                <TabsPanel label={CONSTANT_ACCOUNT.MY_ACCOUNTS}>
                    <MyAccounts
                        isActive={props.activeSubTab === 0}
                        setActiveTab={props.setActiveTab}
                        selectedAccount={props.selectedAccount}
                        setActiveSubTab={props.setActiveSubTab}
                        setSelectedAccount={props.setSelectedAccount}
                        setToast={props.setToast}
                    />
                </TabsPanel>
                {user.allowed_account_creation === "true" && (
                    <TabsPanel label={CONSTANT_ACCOUNT.ACCOUNT_VENDING}>
                        <AccountVending
                            isActive={props.activeSubTab === 1}
                            setActiveTab={props.setActiveTab}
                            selectedAccount={props.selectedAccount}
                            setActiveSubTab={props.setActiveSubTab}
                            setSelectedAccount={props.setSelectedAccount}
                            toast={props.toast}
                            setToast={props.setToast}
                        />
                    </TabsPanel>
                )}
                 <TabsPanel label={CONSTANT_ACCOUNT.UPDATE_ACCOUNT_DETAILS}>
                     <UpdateAccountDetails
                         isActive={props.activeSubTab === 2 || (user.allowed_account_creation === "false" && props.activeSubTab === 1)}
                         setActiveTab={props.setActiveTab}
                         setActiveSubTab={props.setActiveSubTab}
                         setToast={props.setToast}
                         requestType={props.requestType}
                    />
                 </TabsPanel>
                <TabsPanel label={CONSTANT_ACCOUNT.ACCOUNT_CLOSURE}>
                    <AccountClosure
                        isActive={props.activeSubTab === 3 || (user.allowed_account_creation === "false" && props.activeSubTab === 2)}
                        setActiveTab={props.setActiveTab}
                        setActiveSubTab={props.setActiveSubTab}
                        toast={props.toast}
                        setToast={props.setToast}
                    />
                </TabsPanel>
            </Tabs>
        </div>
    );
};

export default AccountManagementObjectPage;