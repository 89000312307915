export const CONSTANT_ACCESS_REQUEST = {
    CREATE_ACCESS_REQUEST: 'Create Access Request',
    ACCESS_REQUESTS: 'Access Requests',
    MY_ACCESS_REQUESTS: 'My Access Requests',
    PENDING_REVIEW_REQUESTS: 'Pending Review Requests',
    SEARCH_OLD_REQUESTS: 'Review Access Requests',
    ACCOUNT_ID: 'Account ID',
    ACCOUNT: 'Account',
    ROLE: 'Role',
    TIME_REMAINING: 'Time Remaining',
    REASON: 'Reason',
    LAUNCH_CONSOLE: 'Launch Console',
    CREDENTIALS: 'Credentials',
    GET_CREDENTIALS: 'Get Credentials',
    REQUESTS_DETAILS: 'Show Details',
    ACCESS_REQUEST_DETAILS: 'Access Request Details',
    REVOKE: 'Revoke',
    SHARE: 'Share',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    EMAIL: 'Email',
    APPROVE: 'Approve',
    REJECT: 'Reject',
    CREATE_BUTTON: 'Create',
    CALCULATED_STATUS_ACTIVE: 'Active',
    CALCULATED_STATUS_PENDING: 'Pending',
    CALCULATED_STATUS_EXPIRED: 'Expired',
    CALCULATED_STATUS_REJECTED: 'Rejected',
    CLOSE_REQUESTED: 'CLOSE_REQUESTED',
    NOTE_LABEL: 'NOTE: ',
    ACCOUNT_PENDING_CLOSE_MSG: 'Account is pending for closure in few days.',
    SELECT_ROLE: 'Select Role',
    ROLE_MORE_DETAILS: 'Learn more about PCSK roles',
    DURATION: 'Duration',
    SELECT_DURATION: 'Select Duration',
    MAJOR_REASON: 'Major Reason',
    SELECT_MAJOR_REASON: 'Select Major Reason',
    MINOR_REASON: 'Minor Reason',
    SELECT_MINOR_REASON: 'Select Minor Reason',
    DETAILED_REASON: 'Detailed Reason',
    DETAILED_REASON_PLACEHOLDER: 'Enter Detailed Reason/ GUS WI/ Case Link',
    NORMAL_ROLES: 'Normal Roles',
    ADMIN_ROLES: 'Admin Roles',
    CUSTOM_ADMIN_ROLES: 'Custom Admin Roles',
    CUSTOM_ROLES: 'Custom Roles',
    GCP_ROLES: 'GCP Roles',
    MAJOR_REASON_DEVELOPMENT: 'Development',
    MAJOR_REASON_DEMO: 'Demo',
    MAJOR_REASON_OTHERS: 'Others',
    ACCOUNT_DETAILS: 'Account Details',
    CLONE_REQUESTS: 'Clone Request',
    SEARCH_ACCOUNT_NO_RECORD: 'No accounts found',
    SEARCH_ACC_REQ_NO_RECORD: 'No access requests found!',
    DURATION_24_HRS: '24 Hours',
    DURATION_7_DAYS: '7 Days',
    DURATION_31_DAYS: '31 Days',
    DURATION_90_DAYS: '90 Days',
    REQUEST_STATUS_ALL: 'All',
    REQUEST_STATUS_ACTIVE: 'Active',
    REQUEST_STATUS_PENDING: 'Pending',
    REQUEST_STATUS_REJECTED: 'Rejected',
    REQUEST_STATUS_EXPIRED: 'Expired',
    ACCESS_TYPE: 'Access Type',
    REQUESTER: 'Requester',
    REVIEWER: 'Reviewer',
    CREATION: 'Creation',
    EXPIRATION: 'Expiration',
    STATUS: 'Status',
    ACTIONS: 'Actions',
    APPROVED_STATUS: 'approved',
    PENDING_STATUS: 'pending',
    COPY_CREDENTIALS: 'Export',
    HIDE_CREDENTIALS: 'Hide Credentials',
    SHOW_CREDENTIALS: 'Reveal Credentials',
    CLOSE_CREDENTIALS: 'Close',
    AWS_ACCESS_KEY_ID: 'AWS_ACCESS_KEY_ID',
    AWS_SECRET_ACCESS_KEY: 'AWS_SECRET_ACCESS_KEY',
    AWS_SESSION_TOKEN: 'AWS_SESSION_TOKEN',
    REVIEW_ACCESS_REQUEST: 'Review Access Request',
    CHANGE_CASE: 'Change case',
    INCIDENT_ID: 'Incident ID',
    EMERGENCY: 'Emergency',
    VALID_CHANGE_CASE: 'Enter a valid change case ID (eg: 56279715)',
    VALID_INCIDENT_ID: 'Enter a valid Incident ID (eg: 56279715)',
    RECORD_DETAILS_LABEL: 'Change Case/Incident Details',
    ENTER_VALID_RECORD_DETAILS_ERROR: 'Please enter a valid change case ID',
    CHANGE_CASE_VALIDATION_ERROR: 'Kindly ensure the entered change case is either approved or not marked as closed.'
}

interface TabsMetadata {
    [key: number]: string;
}

export const TABS_METADATA: TabsMetadata = {
    0: CONSTANT_ACCESS_REQUEST.MY_ACCESS_REQUESTS,
    1: CONSTANT_ACCESS_REQUEST.CREATE_ACCESS_REQUEST,
    2: CONSTANT_ACCESS_REQUEST.SEARCH_OLD_REQUESTS,
};

export const RECORD_TYPE = {
    EMERGENCY: "emergency",
    CHANGE_CASE: "change_case",
    INCIDENT: "incident_id"
}

export function IsMockEnabled(): boolean {
    return false;
}