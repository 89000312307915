import React, { createContext, ReactNode, useContext, useState } from 'react';

interface User {
    id: string;
    email: string;
    user_id: string;
    allowed_account_creation: string;
    roles: string[];
    cloudZones: string[];
}

const UserContext = createContext<{
    user: User;
    setUser: React.Dispatch<React.SetStateAction<User>>;
} | undefined>(undefined);

const initialUser: User = {
    id: "",
    email: "",
    user_id: "",
    allowed_account_creation: "false",
    roles: [],
    cloudZones: []
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User>(initialUser);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};